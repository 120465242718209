var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"warehouse-list"},[(_vm.warehouseList && _vm.warehouseList.length > 0)?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.showListParsed)?_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"2","order-sm":"1","cols":"12","sm":_vm.showListParsed ? '5' : '0',"xl":_vm.showListParsed ? '4' : '0'}},[_c('v-list',{staticClass:"warehouse-list-list pt-0",attrs:{"height":_vm.mapHeight}},[(_vm.google)?_c('AddressFilter',{staticClass:"px-4 pt-4",attrs:{"google":_vm.google},on:{"addressToSearchChanged":_vm.addressToSearchChanged,"resetNearWarehouse":_vm.resetNearWarehouse}}):_vm._e(),_vm._l((_vm.warehouseList),function(warehouse){return _c('v-list-item',{key:warehouse.warehouseId,class:_vm.isBuongusto && 'buongusto-item',attrs:{"two-line":"","dense":""},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse)}}},[(!_vm.isBuongusto)?_c('v-list-item-avatar',{attrs:{"rounded":"0"}},[_c('img',{attrs:{"width":"24","height":"35","src":"/pdv/pin.png","alt":"Immagine pin negozio","aria-hidden":"true"}})]):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-left font-weight-bold mb-2"},[_vm._v(" "+_vm._s(warehouse.address.addressName || warehouse.name)+" ")]),(!_vm.isBuongusto)?_c('v-list-item-subtitle',{staticClass:"warehouse-address"},[_vm._v(" "+_vm._s(warehouse.address.address1)+","+_vm._s(warehouse.address.addressNumber)+" "+_vm._s(warehouse.address.city)+" ("+_vm._s(warehouse.address.province)+") "),(
                  warehouse.metaData.warehouse_locator &&
                    warehouse.metaData.warehouse_locator.PHONE
                )?_c('div',[_vm._v(" Telefono: "+_vm._s(warehouse.metaData.warehouse_locator.PHONE)+" ")]):_vm._e()]):_vm._e()],1)],1)})],2)],1):_vm._e(),_c('v-col',{staticClass:"py-0 py-md-3",attrs:{"order":"1","order-sm":"2","cols":"12","sm":_vm.showListParsed ? '7' : '0',"xl":_vm.showListParsed ? '8' : '0'}},[_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
          lat: _vm.addressToSearch ? _vm.addressToSearch.latitude : 45.67,
          lng: _vm.addressToSearch ? _vm.addressToSearch.longitude : 8.85
        },"zoom":_vm.zoomLevel,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false
        }}},[_vm._l((_vm.warehouseList),function(warehouse,i){return _c('GmapMarker',{key:warehouse.warehouseId,ref:'warehousMarker_' + warehouse.warehouseId,refInFor:true,attrs:{"position":_vm.google &&
              new _vm.google.maps.LatLng(
                warehouse.address.latitude,
                warehouse.address.longitude
              ),"clickable":true,"icon":"/pdv/pin.png"},on:{"click":function($event){return _vm.toggleInfoWindow(warehouse, i)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWarehouse)+" Contenuto ")]),(_vm.addressToSearch)?_c('gmap-circle',{attrs:{"center":{
            lat: _vm.addressToSearch.latitude,
            lng: _vm.addressToSearch.longitude
          },"radius":_vm.addressToSearch.distance,"visible":true,"options":{ fillOpacity: 0.3, strokeWeight: 0.3 }}}):_vm._e()],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }