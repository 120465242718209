var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"warehouse-list"},[_c('v-row',_vm._l((_vm.warehouseList),function(warehouse){return _c('v-col',{key:warehouse.warehouseId,attrs:{"cols":"12","sm":"4"}},[_c('WarehouseCard',{attrs:{"srcImage":_vm.getImagePdvSrv(warehouse),"title":warehouse.address.addressName,"text":warehouse.address.address1 +
              '<br>' +
              warehouse.address.city +
              ' (' +
              warehouse.address.province +
              ') ' +
              warehouse.address.postalcode,"buttonLink":'/punti-vendita/' + warehouse.warehouseId,"buttonText":"Vai alla scheda del Negozio"}})],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }