<template>
  <div class="warehouse-list">
    <v-row no-gutters v-if="warehouseList && warehouseList.length > 0">
      <v-col
        v-if="showListParsed"
        class="py-0 py-md-3"
        order="2"
        order-sm="1"
        cols="12"
        :sm="showListParsed ? '5' : '0'"
        :xl="showListParsed ? '4' : '0'"
      >
        <v-list class="warehouse-list-list pt-0" :height="mapHeight">
          <AddressFilter
            v-if="google"
            :google="google"
            class="px-4 pt-4"
            @addressToSearchChanged="addressToSearchChanged"
            @resetNearWarehouse="resetNearWarehouse"
          />

          <v-list-item
            v-for="warehouse in warehouseList"
            :key="warehouse.warehouseId"
            two-line
            dense
            @click="toggleInfoWindow(warehouse)"
            :class="isBuongusto && 'buongusto-item'"
          >
            <!-- @mouseenter="animateMarker(warehouse, true)"
                  @mouseleave="animateMarker(warehouse, false)" -->

            <v-list-item-avatar rounded="0" v-if="!isBuongusto">
              <img
                width="24"
                height="35"
                src="/pdv/pin.png"
                alt="Immagine pin negozio"
                aria-hidden="true"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-left font-weight-bold mb-2">
                {{ warehouse.address.addressName || warehouse.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="warehouse-address"
                v-if="!isBuongusto"
              >
                {{ warehouse.address.address1 }},{{
                  warehouse.address.addressNumber
                }}
                {{ warehouse.address.city }} ({{ warehouse.address.province }})
                <div
                  v-if="
                    warehouse.metaData.warehouse_locator &&
                      warehouse.metaData.warehouse_locator.PHONE
                  "
                >
                  Telefono: {{ warehouse.metaData.warehouse_locator.PHONE }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        class="py-0 py-md-3"
        order="1"
        order-sm="2"
        cols="12"
        :sm="showListParsed ? '7' : '0'"
        :xl="showListParsed ? '8' : '0'"
      >
        <GmapMap
          ref="mapRef"
          :center="{
            lat: addressToSearch ? addressToSearch.latitude : 45.67,
            lng: addressToSearch ? addressToSearch.longitude : 8.85
          }"
          :zoom="zoomLevel"
          :style="{ width: mapWidth, height: mapHeight }"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false
          }"
        >
          <GmapMarker
            :ref="'warehousMarker_' + warehouse.warehouseId"
            v-for="(warehouse, i) in warehouseList"
            :key="warehouse.warehouseId"
            :position="
              google &&
                new google.maps.LatLng(
                  warehouse.address.latitude,
                  warehouse.address.longitude
                )
            "
            :clickable="true"
            icon="/pdv/pin.png"
            @click="toggleInfoWindow(warehouse, i)"
          />
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick.stop="infoWinOpen = false"
          >
            {{ selectedWarehouse }}
            Contenuto
          </gmap-info-window>
          <gmap-circle
            v-if="addressToSearch"
            :center="{
              lat: addressToSearch.latitude,
              lng: addressToSearch.longitude
            }"
            :radius="addressToSearch.distance"
            :visible="true"
            :options="{ fillOpacity: 0.3, strokeWeight: 0.3 }"
          >
          </gmap-circle>
        </GmapMap>
      </v-col>
    </v-row>
  </div>
</template>
<style>
@import "/p/wp-content/themes/tigros2020/styles-common.css";
</style>
<style lang="scss">
.warehouse-list-list {
  height: 380px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: initial;
  }
  .buongusto-item {
    min-height: 20px !important;
    height: 50px !important;
  }
  overflow-y: auto;
  .warehouse-address {
    line-height: initial;
  }
  .v-list-item__title {
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-size: 14px;
    font-weight: bold;
  }
  .v-list-item__subtitle {
    color: $text-color;
  }
  .v-avatar {
    padding: 2px;
    margin: 0;
    img {
      width: 24px;
      height: 34px;
    }
  }
}
.gm-style-iw .gm-style-iw {
  max-height: 180px !important;
}
.gm-style {
  .gm-style-iw-c {
    max-height: 180px !important;
    .gm-style-iw-d {
      max-height: 180px !important;
    }
  }
}
#infoWindowDialog {
  text-align: left;
  cursor: pointer;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--v-primary-base);

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--v-primary-base);
  }
  .buongusto-infowindow-btn {
    color: var(--v-primary-base) !important;
  }
}
</style>
<script>
import AddressFilter from "@/components/address/AddressFilter.vue";

import AddressService from "~/service/addressService";

import { gmapApi } from "vue2-google-maps";
import forEach from "lodash/forEach";

export default {
  name: "WarehouseMap",
  components: { AddressFilter },
  props: {
    showList: { type: String, default: "false" },
    regionId: { type: String, required: false },
    provinceId: { type: String, required: false },
    parentAddressClassId: { type: String, required: false },
    showLocker: { type: String, default: "false" }
  },
  data() {
    return {
      showListParsed: false,
      showLockerParsed: false,
      warehouseList: [],
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWarehouse: null,
      selectedRegionId: null,

      addressToSearch: null
    };
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "300px" : "480px";
    },
    pageContent() {
      return this.page && this.page.content ? this.page.content.rendered : "";
    },
    pageTitle() {
      return this.page && this.page.title ? this.page.title.rendered : "";
    },
    // Questa computed serve solo per Tigros
    isBuongusto() {
      return this.parentAddressClassId === "6";
    },
    zoomLevel() {
      if (this.addressToSearch) {
        return this.addressToSearch.distance == 10000 ? 12 : 10;
      } else {
        return this.$vuetify.breakpoint.xsOnly ? 8 : 9;
      }
    }
  },
  methods: {
    resetNearWarehouse() {
      this.addressToSearch = null;
    },
    async addressToSearchChanged(addressToSearch) {
      this.addressToSearch = addressToSearch;
      await this.findWarehouse();
    },
    async findWarehouse() {
      let searchParams = {
        province_id: this.provinceId,
        parent_address_class_id: this.parentAddressClassId,
        region_id: this.regionId
      };
      if (this.addressToSearch) {
        searchParams["parent_latitude"] = this.addressToSearch.latitude;
        searchParams["parent_longitude"] = this.addressToSearch.longitude;
        searchParams["parent_distance"] = this.addressToSearch.distance;
      }
      let data = await AddressService.findWarehouse(searchParams);
      this.warehouseList = data.warehouses;
      console.log("WL", this.warehouseList);
    },
    async findLocker() {
      this.warehouseList = [];
      let data = await AddressService.getAddressByDeliveryService(4);
      forEach(data.addresses, a => {
        this.warehouseList.push({ metaData: {}, address: a });
      });
    },
    animateMarker(warehouse, status) {
      if (status) {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = this.google.maps.Animation.BOUNCE;
      } else {
        this.$refs[
          "warehousMarker_" + warehouse.warehouseId
        ][0].animation = null;
      }
    },
    toggleInfoWindow(warehouse) {
      this.infoWindowPos = {
        lat: warehouse.address.latitude,
        lng: warehouse.address.longitude
      };
      let phoneNumber = warehouse.metaData.warehouse_locator?.PHONE || " -- ";

      let infoWindowBtn = null;
      if (this.isBuongusto) {
        infoWindowBtn = `<a href='/ristoranti-buongusto/${warehouse.metaData.warehouse_locator?.SLUG_BUONGUSTO}' class='buongusto-infowindow-btn w-100 pa-3 v-btn v-btn--has-bg v-btn--router theme--light secondary'>VAI ALLA SCHEDA DEL RISTORANTE</a>`;
      } else {
        infoWindowBtn = `<a class='v-btn v-btn--text primary--text v-size--small' target='system' href='https://www.google.it/maps/dir//${warehouse.name}@/${warehouse.address.latitude},${warehouse.address.longitude}'>
            COME ARRIVARE
          </a><span width="10px"></span> <a class='v-btn secondary v-size--small primary--text' href='/punti-vendita/${warehouse.warehouseId}'>
            VAI ALLA SCHEDA
          </a>`;
      }
      if (this.isBuongusto) {
        infoWindowBtn = "";
      }
      this.infoOptions.content = `<div id='infoWindowDialog' style='font-family: Open Sans, arial, sans-serif'>
          <h3 class='d-flex align-center' style='line-height: 1.2rem;'>
            ${warehouse.address.addressName || warehouse.name}
          </h3>
          <div class='mb-1'>
            ${warehouse.address.address1} ${
        warehouse.address.addressNumber
      } <br/>
            ${warehouse.address.city} ( ${warehouse.address.province} ) ${
        warehouse.address.postalcode
      } <br/>
            <strong>Telefono:</strong> ${phoneNumber} <br/>
          </div>
          ${infoWindowBtn}
        </div>`;

      //check if its the same marker that was selected if yes toggle
      if (
        this.selectedWarehouse &&
        this.selectedWarehouse.warehouseId == warehouse.warehouseId
      ) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWarehouse = warehouse;
      }
    }
  },
  mounted() {
    this.showListParsed = this.showList == "true" ? true : false;
    this.showLockerParsed = this.showLocker == "true" ? true : false;

    if (this.showLockerParsed) {
      this.findLocker();
    } else {
      this.findWarehouse();
    }
  }
};
</script>
