<template>
  <v-card
    v-if="design === 'design-7' || design === 'card'"
    :href="slide.link"
    elevation="0"
    :class="'ma-2 text-center ' + sliderId"
    outlined
    @click="handleClick"
    v-intersect.once="handleView"
  >
    <img :src="slide.featured_image_url" style="display:block;margin:0 auto;" />
    <v-card-text
      v-html="slide.title"
      class="swiper-slider-overlay text-center"
    ></v-card-text>
  </v-card>

  <div
    v-else-if="design === 'design-8'"
    class="wpsisac-image-slide slick-slide slick-cloned slick-active"
    v-intersect.once="handleView"
  >
    <div class="container-point">
      <div class="timeline-point"></div>
      <div class="timeline-line"></div>
    </div>
    <div class="wpsisac-image-slide-wrap">
      <div class="wpsisac-slider-overlay">
        <div class="container-point">
          <div class="timeline-point"></div>
          <div class="timeline-line"></div>
        </div>
        <div class="wpsisac-slider-content">
          <div class="wpsisac-slider-short-content">
            <div v-html="slide.title"></div>
            <div id="" class="after-content">
              <div class="container "></div>
              <!-- #xsbf-after-content -->
            </div>
            <!-- .container -->
          </div>
        </div>
      </div>

      <img
        class="swiper-slide-img"
        style="max-width:100%;"
        :src="slide.featured_image_url"
      />
    </div>
  </div>
  <a
    v-else-if="slide.link"
    :href="slide.link"
    @click="handleClick"
    v-intersect.once="handleView"
  >
    <img
      class="swiper-slide-img"
      style="max-width:100%;"
      :src="slide.featured_image_url"
    />
  </a>
  <img
    v-else
    class="swiper-slide-img"
    style="max-width:100%;"
    :src="slide.featured_image_url"
    v-intersect.once="handleView"
  />
</template>
<style lang="scss">
.wordpress-slider-container {
  .swiper-slide-img {
    width: 100%;
  }
}
</style>
<style>
@import "/p/wp-content/themes/tigros2020/styles-common.css";
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import analyticsService from "~/service/analyticsService";

export default {
  name: "WordPressSliderCard",
  props: {
    sliderId: { type: String, required: true },
    slide: { type: Object, required: true },
    design: { type: String, required: false }
  },
  mixins: [clickHandler],
  methods: {
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting && this.slide) {
        analyticsService.viewPromotions(
          {
            id: this.slide.id,
            name: this.slide.featured_image_url
              .split("/")
              .slice(-1)[0]
              .split(".")[0],
            img: this.slide.featured_image_url
          },
          this.sliderId
        );
      }
    },
    handleClick(e) {
      analyticsService.clickPromotion(
        {
          id: this.slide.id,
          name: this.slide.featured_image_url
            .split("/")
            .slice(-1)[0]
            .split(".")[0],
          img: this.slide.featured_image_url
        },
        this.sliderId
      );
      this.clicked(e);
    }
  }
};
</script>
