<template>
  <div class="wordpress-slider-container slider banner-slider" v-if="slides">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="slide in slides"
        :key="slide.menu_order"
        :ref="swiperRef"
        :class="design === 'design-8' ? 'ma-0' : null"
      >
        <word-press-slider-card
          :slide="slide"
          :slider-id="sliderId"
          :design="design"
        />
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && slides.length > 1"
      :id="`banner-pagination-${sliderId}`"
      :class="
        `swiper-pagination swiper-pagination-clickable swiper-pagination-bullets banner-pagination-${sliderId}`
      "
      slot="pagination"
    ></div>
    <div
      v-if="showArrows && slides.length > 1"
      :id="`banner-slider-prev-${sliderId}`"
      :class="`swiper-button-prev banner-slider-prev-${sliderId}`"
      slot="button-prev"
    ></div>
    <div
      v-if="showArrows && slides.length > 1"
      :id="`banner-slider-next-${sliderId}`"
      :class="`swiper-button-next banner-slider-next-${sliderId}`"
      slot="button-next"
    ></div>
  </div>
</template>
<style lang="scss">
.wordpress-slider-container {
  .swiper-slide-img {
    width: 100%;
  }
}
</style>
<style>
@import "/p/wp-content/themes/tigros2020/styles-common.css";
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import CMSService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";
import WordPressSliderCard from "./components/WordPressSliderCard.vue";

export default {
  components: { WordPressSliderCard },
  name: "WordPressSlider",
  props: {
    sliderId: { type: String, required: true },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 3 },
    xl: { default: 4 },
    // FOR BACKWARD COMPATIBILITY
    dots: { type: String, default: "true" },
    arrows: { type: String, default: "true" },
    autoplay: { type: String, default: "true" },
    slidestoshow: { type: String, default: null },
    design: { type: String, default: null }
  },
  mixins: [deliveryReactive, clickHandler],
  data() {
    return {
      slides: null,
      swiperRef: null,
      showArrows: true,
      showBullets: true,
      swiperOption: {
        spaceBetween: 20,
        autoplay: {
          disableOnInteraction: true
        },
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `.banner-pagination-${this.sliderId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#banner-slider-prev-${this.sliderId}`,
          nextEl: `#banner-slider-next-${this.sliderId}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.slidestoshow || this.lg
          },
          1904: {
            slidesPerView: this.slidestoshow || this.xl || this.lg
          }
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
    }
  },
  async created() {
    const res = await CMSService.getSliderBySlug(this.sliderId);
    this.slides = res;

    if (this.dots === "false") {
      this.showBullets = false;
    }

    if (this.arrows === "false") {
      this.showArrows = false;
    }
  }
};
</script>
